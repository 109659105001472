import {
	AfterViewInit,
	Component,
	effect,
	inject,
	Input,
	OnInit,
	signal,
	ViewChild,
	ViewEncapsulation,
	WritableSignal,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from "@angular/forms";
import { ProgramsScheduleService } from "@sportyano/core/services/academy/programs-schedule/programs.schedule.service";
import {
	USER_TYPE,
	USERS_TYPE,
	UserType,
} from "@sportyano/core/models/user-type/user-type";
import {
	map,
	Observable,
	Subject,
	takeUntil,
	takeWhile,
	tap,
	timer,
} from "rxjs";
import { RegisterResponse } from "@sportyano/core/models/authentications/register-data.model";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
	CustomValidationType,
	CustomValidators,
	PatternValidation,
	VariablesValidation,
} from "@sportyano/shared/directives/custom-validation-reactiveForm";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { permission } from "@sportyano/core/models/permissions/permission";
import { NgOtpInputModule } from "ng-otp-input";
import { CommonModule } from "@angular/common";
import { TabViewModule } from "primeng/tabview";
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";
import { programDetailsQuickComponent } from "@sportyano/main-site/academies/program-details-quick/program-details-quick.component";
import {
	RECAPTCHA_V3_SITE_KEY,
	RecaptchaV3Module,
	ReCaptchaV3Service,
} from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { MatButtonModule } from "@angular/material/button";
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from "@angular/material/dialog";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { HeaderComponent } from "@sportyano/core/header/header.component";
import { ANIMATIONS } from "@sportyano/shared/animation/animation";
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { IconComponent } from "@sportyano/shared/components/icon/icon.component";
import { ICONS } from "@sportyano/shared/components/icon/models/icon";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import {
	PAYMENT_METHOD,
	PaymentMethodsType,
} from "@sportyano/main-site/booking/booking/models/const/booking.const";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { IOtpRequest } from "@sportyano/core/models/authentications/otp-model";
import { PlaygroundDetailsComponent } from "@sportyano/main-site/playgrounds/playground-details/playground-details.component";
import { CalendarSchedulerComponent } from "@sportyano/shared/components/calendar-scheduler/calender-scheduler.component";
import { ITimeSlotRequest } from "@sportyano/core/models/programs/time-slots.model";
import { MatExpansionModule } from "@angular/material/expansion";
import { BookingService } from "@sportyano/core/services/booking/booking.service";
import {
	IBookingRequest,
	IMultipleProgramsBookingRequest,
} from "@sportyano/main-site/booking/booking/models/interfaces/booking.interface";
import { StateManagementService } from "@sportyano/core/services/state-managment/state-management.service";
import { ViewerService } from "@sportyano/core/services/viewer-profile/viewer.service";
import { Profile } from "@sportyano/core/models/account/account";
import {
	QUICK_REGISTER_MODULE_TYPE,
	QuickRegistrationModalType,
} from "@sportyano/shared/components/quick-register/models/const/modal-type.const";
import { AcademyProgramReservationComponent } from "@sportyano/main-site/academies/academy-program-reservation/academy-program-reservation.component";
import { IAcademyProgram } from "@sportyano/core/models/single-academy-details/single-academy.model";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { PreventCopyCutPasteDirective } from "@sportyano/shared/directives/prevent-copy-cut-paste/prevent-copy-cut-paste.directive";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { CheckboxModule } from "primeng/checkbox";

@Component({
	selector: "app-quick-register",
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TabViewModule,
		TranslateModule,
		NgOtpInputModule,
		CheckLanguageDirective,
		programDetailsQuickComponent,
		RecaptchaV3Module,
		MatButtonModule,
		MatDialogModule,
		MatButtonModule,
		MatStepperModule,
		HeaderComponent,
		LottieComponent,
		IconComponent,
		NgxSpinnerModule,
		LoadingBarModule,
		PlaygroundDetailsComponent,
		CalendarSchedulerComponent,
		MatExpansionModule,
		AcademyProgramReservationComponent,
		MatButtonToggleModule,
		PreventCopyCutPasteDirective,
		MatTooltipModule,
		MatIconModule,
		CheckboxModule,
	],
	providers: [
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha_site_key,
		},
	],
	templateUrl: "./quick-register.component.html",
	styleUrl: "./quick-register.component.scss",
	encapsulation: ViewEncapsulation.None,
	animations: ANIMATIONS,
})
export class QuickRegisterComponent implements OnInit, AfterViewInit {
	// inject
	private _fb = inject(FormBuilder);
	private _programSchedule = inject(ProgramsScheduleService);
	breakpointObserver = inject(BreakpointObserver);
	private _toast = inject(ToasterMessagesService);
	private _authService = inject(AuthService);
	public translateService = inject(TranslateService);
	private recaptchaV3Service = inject(ReCaptchaV3Service);
	public data = inject(MAT_DIALOG_DATA);
	private _dialogRef = inject(MatDialogRef<QuickRegisterComponent>);
	private services = inject(PlayerService);
	private activatedRoute = inject(ActivatedRoute);
	private _bookingService = inject(BookingService);
	private _router: Router = inject(Router);
	private _stateManagementService = inject(StateManagementService);
	private _viewerService: ViewerService = inject(ViewerService);
	private _toastrMessages = inject(ToasterMessagesService);

	// Input
	@Input() details = this.data?.details;
	@Input() type: QuickRegistrationModalType = this.data?.type;
	@Input() registerAs = this.data?.registerAs;

	//ViewChild
	@ViewChild("stepper") stepper!: MatStepper; // Access the stepper using @ViewChild
	// Signals
	public resendOtpAttempts: WritableSignal<number> = signal<number>(0);
	public countdownSeconds: WritableSignal<number> = signal<number>(0);
	public countdownRedirect: WritableSignal<number> = signal<number>(30);
	public showOTPStep: WritableSignal<boolean> = signal(false);
	private _resendOTPToken: WritableSignal<string | null> = signal<
		string | null
	>(null);
	// Signals
	public selectedSlots: WritableSignal<Array<ITimeSlotRequest>> = signal<
		Array<ITimeSlotRequest>
	>([]);
	// Forms
	public playerDataInfo: FormGroup;
	public academyRegistrationForm: FormGroup;
	public otpFormControl = new FormControl();
	public programForm: FormGroup;
	basicInfoForm: FormGroup; // Form group for Basic Info
	public paymentMethodForm: FormGroup;
	public paymentPercentage: FormControl = this._fb.control(100);
	// Private
	private _playerId: number = 0;
	private _routeName: string;
	private _userType: UserType;
	private _isMobileScreen: boolean;
	private _password: string;
	private storedSubmittedDatesForReview: Array<number | string> = [];
	private selectedStartingDate: string;
	private _access_token: string = "";

	// Public
	public ICONS = ICONS;
	public USER_TYPE = USER_TYPE;
	public QUICK_REGISTER_MODULE_TYPE = QUICK_REGISTER_MODULE_TYPE;
	public USERS_TYPE = USERS_TYPE;
	public selectedPrograms: Map<number, IAcademyProgram> = new Map();
	public permission = permission;
	public maximumOtpAttempts: number = 5;
	public CustomValidationType = CustomValidationType;
	public passwordVisibility: boolean = true;
	// public policyCheckbox: boolean = false;
	public todayDate: Date = new Date();
	public playgroundBookingPrice: number = 0;
	public PAYMENT_METHOD = { cash: 1, online: 2, other: 3 };
	public isOnlinePaymentDisabled: boolean;
	public availablePaymentTypes: {
		key: string;
		name: string;
		icon: string;
		provider: string;
	}[] = [];
	viewerProfiles: Profile[] = [];
	viewerSelectedId: number;

	public congratsLottie: AnimationOptions = {
		path: "/assets/json/success.json",
		loop: false,
	};
	private _countDownDestroy$: Subject<void> = new Subject<void>();
	public isDialogVisible$: Observable<boolean> =
		this._authService.getQuickLoginPopupState();
	private unsubscribe$: Subject<void> = new Subject<void>();

	public get getTotalSelectedProgram(): number {
		let total = 0;
		this.selectedPrograms.forEach((program) => {
			total += program.total_amount || 0;
		});
		return total;
	}

	public get getSelectedProgramsIds(): Array<number> {
		const ids: Array<number> = [];
		this.selectedPrograms.forEach((program) => {
			ids.push(program.id);
		});
		return ids;
	}

	constructor() {
		effect(() => {
			this._calculatePrice(this.selectedSlots());
		});
	}

	ngOnInit(): void {
		this.initializeForm();
		this.checkScreenSize();
		this._password = this._generateRandomPassword();
		this.buildQuickRegisterForm();
		this._getOnlinePaymentWays();
	}

	ngAfterViewInit(): void {
		this._listenToLastStepForNavigation();
	}

	private _listenToLastStepForNavigation(): void {
		this.stepper.selectionChange.subscribe(
			(event: StepperSelectionEvent) => {
				if (event.selectedIndex === 3) {
					this.onDoneStepActivated();
				}
			}
		);
	}

	private _generateRandomPassword(): string {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?";
		const passwordLength = Math.floor(Math.random() * (15 - 6 + 1)) + 6; // Random length between 6 and 15
		this._password = "";

		// Generate the password
		for (let i = 0; i < passwordLength; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			this._password += characters[randomIndex];
		}

		return this._password;
	}

	private removeQueryParams(): void {
		const url = new URL(window.location.href);
		url.search = ""; // Remove all query parameters
		window.history.replaceState(null, "", url.toString());
	}

	private onDoneStepActivated() {
		this.countdownRedirect.set(30);
		timer(0, 1000)
			.pipe(
				takeUntil(this._countDownDestroy$),
				map(() => {
					return this.countdownRedirect();
				}),
				takeWhile((seconds) => seconds > 0)
			)
			.subscribe({
				next: () => {
					this.countdownRedirect.update((s) => s - 1);
					return this.countdownRedirect();
				},
				complete: () => {
					this.removeQueryParams();
					this.closeDialog();
					this._destroyCounter();
					window.location.reload();
				},
			});
	}

	buildQuickRegisterForm() {
		this.programForm = this._fb.group({
			username: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			phone_number: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			password: [
				this._password,
				[
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.password_minLength,
						VariablesValidation.password_maxLength
					),
				],
			],
			recaptcha: ["", Validators.required],
		});

		// To set the phone_number as the value of username:
		this.programForm.get("username")?.valueChanges.subscribe((value) => {
			this.programForm.get("phone_number")?.setValue(value);
		});
	}

	sendOtpToPhone() {
		this.recaptchaV3Service.execute("importantActionQuick").subscribe({
			next: (token: string) => {
				if (token) {
					// Set the received token to the recaptcha form control
					this.programForm.get("recaptcha")?.setValue(token);
					this.programForm.patchValue({ recaptcha: token });

					// Proceed with submitting the form
					this._authService
						.signUp(this.programForm.value, "viewer")
						.subscribe({
							next: (res: RegisterResponse) => {
								this._authService.setRegisterSessionCycle(
									"createAccountToken",
									res.token
								);
								if (res.token) {
									this.showOTPStep.set(true);
									this._startCountdown();
									this._authService.setRegisterSessionCycle(
										"autoLoginData",
										res
									);
								}
							},
							error: (err) => {
								// Handle error from sign-up API
								console.error("Error during sign-up:", err);
							},
						});
				} else {
					// Handle case where no token was received
					console.error("reCAPTCHA token is empty or invalid");
				}
			},
			error: (err) => {
				// Handle error when reCAPTCHA execution fails
				console.error("Error executing reCAPTCHA:", err);
			},
		});
	}

	get email() {
		return this.playerDataInfo.get("email");
	}
	get player_id() {
		return this.playerDataInfo.get("player_id");
	}

	// ------------initializeForm and Valiations----------------------//
	initializeForm() {
		switch (this.registerAs) {
			case USER_TYPE.viewer:
				this._initViewerForm();
				break;

			case USER_TYPE.academy:
				this._initAcademyForm();
				break;

			default:
				break;
		}

		this.paymentMethodForm = this._fb.group<{
			payment_method: FormControl<number | null>;
			payment_gateway_provider: FormControl<string | null>;
			payment_type: FormControl<string | number | null>;
		}>({
			payment_method: this._fb.control(null, Validators.required),
			payment_gateway_provider: this._fb.control(null),
			payment_type: this._fb.control(null),
		});
	}

	private _initViewerForm() {
		this.playerDataInfo = this._fb.group({
			first_name_ar: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.arabicOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			last_name_ar: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.arabicOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			first_name_en: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.englishOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			last_name_en: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.englishOnly(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.length_2,
						VariablesValidation.length_50
					),
				],
			],
			email: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.emailPatternValidation(),
				],
			],
		});
	}

	private _initAcademyForm() {
		this.academyRegistrationForm = this._fb.group({
			name_ar: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.register_name_en_minLength,
						VariablesValidation.register_name_en_maxLength
					),
				],
			],
			name_en: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.register_name_en_minLength,
						VariablesValidation.register_name_en_maxLength
					),
				],
			],
			contact_name: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.register_contact_name_minLength,
						VariablesValidation.register_contact_name_maxLength
					),
					CustomValidators.patternValidation(
						PatternValidation.First_Second_Name
					),
					CustomValidators.englishOnly(),
				],
			],
			password: [
				this._password,
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.password_minLength,
						VariablesValidation.password_maxLength
					),
					CustomValidators.patternValidation(
						PatternValidation.passwordPattern
					),
				],
			],
			phone_number: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			recaptcha: [""],
		});
	}

	async onRegisterSubmit() {
		switch (this.registerAs) {
			case USER_TYPE.viewer: {
				// const firstName =
				// 	this.playerDataInfo.get("firstName")?.value || "";
				// const lastName =
				// 	this.playerDataInfo.get("lastName")?.value || "";
				// const fullName = firstName + " " + lastName;

				// // Regular expression to check for Arabic characters
				// const isArabic = /[\u0600-\u06FF]/.test(fullName);

				// const formPayload = {
				// 	name_ar: isArabic ? fullName : null,
				// 	name_en: isArabic ? null : fullName,
				// 	email: this.playerDataInfo.get("email")?.value,
				// };

				const formPayload = {
					name_ar:
						this.playerDataInfo.get("first_name_ar")?.value +
						" " +
						this.playerDataInfo.get("last_name_ar")?.value,
					name_en:
						this.playerDataInfo.get("first_name_en")?.value +
						" " +
						this.playerDataInfo.get("last_name_en")?.value,
					email: this.playerDataInfo.get("email")?.value,
				};

				this.services
					.createViewerProfileQuick(
						this._routeName,
						formPayload,
						this._playerId,
						this._access_token
					)

					.subscribe({
						next: (res) => {
							this.isRegisteredSuccess = true;
							this.getViewerProfiles();
							this.goToNextTab();
						},
						error: (err) => {
							console.log(err);
						},
					});
			}
		}
	}
	isProgramDetails: boolean = true;
	goToPhoneRegister() {
		this.goToNextTab();
		this.isProgramDetails == false;
	}

	checkScreenSize(): void {
		this.breakpointObserver
			.observe([
				Breakpoints.Handset, // Mobile devices
				Breakpoints.Tablet, // Tablet devices
				Breakpoints.Web, // Desktop devices
			])
			.subscribe((result) => {
				if (result.matches) {
					// Mobile or Tablet view
					this._isMobileScreen = true;
				} else {
					// Desktop view
					this._isMobileScreen = false;
				}
			});
	}

	public closeDialog() {
		this._dialogRef.close();
	}

	public resendOtp(): void {
		if (!this.countdownSeconds()) {
			this._sendOtp();
		}
	}
	private _sendOtp(): void {
		const userPhone =
			this._authService.getRegisterSessionCycle().autoLoginData.user
				.username;
		this._authService
			.resendOTP(userPhone)
			.pipe(
				tap((response) => {
					// Store  token
					this._resendOTPToken.set(response.token);
				})
			)
			.subscribe({
				next: (response) => {
					this._showSuccessToaster(response.message);
					this.resendOtpAttempts.set(response?.resend_attempts);
					this._startCountdown();
				},
				error: () => {
					this._resendOTPToken.set(null);
				},
			});
	}

	private handleCreateAccountVerification(dataToSend: any): void {
		this._authService
			.otpVerification(dataToSend)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (response) => {
					this._showSuccessToaster(
						"authentication.registeration_types.registeredSuccessfully"
					);
					if (response.status != "success") {
						this._toast.showError(
							"OTP verification failed. Please try again."
						);
					}

					this._authService.setToken = response.access_token;
					this._access_token = response.access_token;

					const autoLoginData =
						this._authService.getRegisterSessionCycle()
							.autoLoginData;

					this._authService.setAuthenticationData(autoLoginData);
					this._authService.setToken = response.access_token;

					this.goToNextTab();
				},
			});
	}

	submitOtp() {
		let dataToSend: IOtpRequest;

		dataToSend = {
			token: this._authService.getRegisterSessionCycle().autoLoginData
				.token,
			otp: this.otpFormControl.value,
		};

		if (this.registerAs == USER_TYPE.viewer) {
			this._playerId =
				this._authService.getRegisterSessionCycle().autoLoginData.user.id;
			this._userType =
				this._authService.getRegisterSessionCycle().autoLoginData.userType;
			this._routeName = this._userType + "s";
			this.player_id?.patchValue(this._playerId);
		}

		this.handleCreateAccountVerification(dataToSend);
	}

	private _destroyCounter(): void {
		this._countDownDestroy$.next();
		this._countDownDestroy$.complete();
	}

	private _startCountdown(): void {
		this.countdownSeconds.set(60);
		timer(0, 1000)
			.pipe(
				takeUntil(this._countDownDestroy$),
				map(() => {
					return this.countdownSeconds();
				}),
				takeWhile((seconds) => seconds > 0)
			)
			.subscribe({
				next: () => {
					this.countdownSeconds.update((s) => s - 1);
					return this.countdownSeconds();
				},
				complete: () => {
					this._destroyCounter();
				},
			});
	}

	private _showSuccessToaster(message: string): void {
		this._toast.showSuccess(this.translateService.instant(message));
	}

	isRegisteredSuccess: boolean = false;

	goToNextTab(): void {
		this.stepper.next();
	}

	private _calculatePrice(selectedSlots: Array<ITimeSlotRequest>): void {
		if (this.details?.price) {
			this.playgroundBookingPrice = selectedSlots.reduce(
				(total, slot) => total + slot?.price!,
				0
			);
		}
	}

	onBooking() {
		switch (this.type) {
			case QUICK_REGISTER_MODULE_TYPE.playground:
				this.onPlaygroundBooking();
				break;

			case QUICK_REGISTER_MODULE_TYPE.academy:
				this.onProgramsBooking();
				break;

			case QUICK_REGISTER_MODULE_TYPE.program:
				this._academyProgramBooking(this.details.id);
				break;
			default:
				break;
		}
	}

	public setPaymentMethod(method: number) {
		if (this.paymentMethodForm.value.payment_method != method) {
			this.paymentMethodForm.patchValue({
				payment_method: method,
				payment_gateway_provider:
					method === this.PAYMENT_METHOD.cash
						? null
						: this.paymentMethodForm.get("payment_gateway_provider")
								?.value,
				payment_type:
					method === this.PAYMENT_METHOD.cash
						? null
						: this.paymentMethodForm.get("payment_type")?.value,
			});
		}
	}

	private _getOnlinePaymentWays(): void {
		this._bookingService.getOnlinePaymentWay().subscribe({
			next: (res) => {
				const paymentTypes = JSON.parse(res.data) || {};
				this.availablePaymentTypes = [];

				Object.entries(paymentTypes).forEach(
					([provider, types]: [string, any]) => {
						Object.entries(types).forEach(([key, value]) => {
							this.availablePaymentTypes.push({
								key: key, // The key to be sent to BE
								name: value as string,
								provider: provider, // Needed for BE
								icon: this._getIconForPaymentType(
									value as string
								),
							});
						});
					}
				);
				// Disable Online Payment if no types are available
				this.isOnlinePaymentDisabled =
					this.availablePaymentTypes.length === 0;
			},
			error: () => {
				console.error("Error fetching payment types");
				this.isOnlinePaymentDisabled = true; // Disable in case of error
			},
		});
	}

	private _getIconForPaymentType(type: string): string {
		const icons: { [key: string]: string } = {
			fawry: "assets/images/image/online-wallet-fawry.webp",
			paymob: "assets/images/image/paymob.png",
			xpay: "assets/images/image/xpay-logo.svg",
			kiosk: "assets/images/image/online-wallet-aman.webp",
			card: "assets/images/image/visa-and-master-card.webp",
			mobile_wallet: "assets/images/image/payment-online-wallet.webp",
			other: "assets/images/image/payment-online-instapay.webp",
		};
		return icons[type] || "assets/images/image/default.png";
	}

	public isOnlineSelected(): boolean {
		return (
			this.paymentMethodForm.get("payment_method")?.value ===
				this.PAYMENT_METHOD.online && !this.isOnlinePaymentDisabled
		);
	}

	public selectPaymentType(type: { key: string; provider: string }) {
		this.paymentMethodForm.patchValue({
			payment_type: type.key,
			payment_gateway_provider: type.provider,
		});
	}

	public isSelectedPaymentType(typeKey: string): boolean {
		return this.paymentMethodForm.get("payment_type")?.value === typeKey;
	}

	private _playgroundBooking(payload: IBookingRequest): void {
		this._programSchedule
			.playgroundBooking(this.details?.id as string, payload)
			.subscribe({
				next: (res) => {
					if (res.status == "success") {
						this._bookingNavigation({
							payment_url: res.payment_url,
							payment_method: payload.payment_method,
						});
					}
					// STORE
					this.storedSubmittedDatesForReview = [
						...this.selectedSlots().map((ele: ITimeSlotRequest) => {
							return ele.slot;
						}),
					];
				},
			});
	}

	public onPlaygroundBooking() {
		let payload: any;
		const paymentMethod =
			this.paymentMethodForm.get("payment_method")?.value;

		switch (this.type) {
			case USER_TYPE.playground:
				const selectedSlots = this.selectedSlots();

				const timeSlots = selectedSlots
					.filter((ele: ITimeSlotRequest) => ele.slotId !== undefined)
					.map((ele: ITimeSlotRequest) => ele.slotId);

				const slots = selectedSlots
					.filter(
						(ele: ITimeSlotRequest) =>
							ele.slotId === undefined && ele.slot !== undefined
					)
					.map((ele: ITimeSlotRequest) => ele.slot);

				payload = {
					starting_date: this.selectedStartingDate,
					...(timeSlots.length > 0 && { time_slots: timeSlots }),
					...(slots.length > 0 && { slots: slots }),
					payment_method: paymentMethod,
				};

				this._stateManagementService.setSessionStorage("booking", {
					...this.details,
					payloadData: payload,
					bookingType: USER_TYPE.playground,
					selectedSlots,
					bookingPrice: this.playgroundBookingPrice,
				});
				break;
			case USER_TYPE.academy:
				this._stateManagementService.setSessionStorage("booking", {
					...this.details,
					payloadData: payload,
					bookingType: this._authService.getUserType(),
				});
				break;
			default:
				break;
		}

		if (paymentMethod === PAYMENT_METHOD.visa) {
			payload = {
				...payload,
				payer_name:
					this.playerDataInfo.get("first_name_en")?.value +
					" " +
					this.playerDataInfo.get("last_name_en")?.value,
				payment_gateway_provider: this.paymentMethodForm.get(
					"payment_gateway_provider"
				)?.value,
				payment_type: this.paymentMethodForm.get("payment_type")?.value,
			};
		}

		if (
			this._userType === permission.viwer &&
			this.viewerProfiles.length > 0
		) {
			payload.profile_id = this.viewerSelectedId;
		}

		this._playgroundBooking(payload);
	}

	private getViewerProfiles() {
		this._viewerService.getViewerProfile(1).subscribe({
			next: (res) => {
				if (res[0]) {
					this.viewerProfiles = res[0].profiles[0];
				}
				if (this.viewerProfiles.length > 0) {
					this.viewerSelectedId = this.viewerProfiles[0].id;
				}
			},
		});
	}

	public onProgramsBooking() {
		let payload: any = {
			programs: this.getSelectedProgramsIds,
			payment_method: this.paymentMethodForm.get("payment_method")?.value,
			downpayment_amount: this.paymentPercentage.value,
		};

		if (this.isOnlineSelected()) {
			payload.payer_name =
				this.academyRegistrationForm.get("contact_name")?.value;

			payload.payment_gateway_provider = this.paymentMethodForm.get(
				"payment_gateway_provider"
			)?.value;
			payload.payment_type =
				this.paymentMethodForm.get("payment_type")?.value;
		}

		this._stateManagementService.setSessionStorage("booking", {
			...this.selectedPrograms.values(),
			payloadData: payload,
			bookingType: USER_TYPE.academy,
		});

		this._multipleProgramsBooking(payload);
	}

	private _academyProgramBooking(programId: string) {
		let payload: any = {
			payment_method: this.paymentMethodForm.get("payment_method")?.value,
		};

		if (this.isOnlineSelected()) {
			(payload.payer_name =
				this.playerDataInfo.get("first_name_en")?.value +
				" " +
				this.playerDataInfo.get("last_name_en")?.value),
				(payload.payment_gateway_provider = this.paymentMethodForm.get(
					"payment_gateway_provider"
				)?.value);
			payload.payment_type =
				this.paymentMethodForm.get("payment_type")?.value;
		}

		this._stateManagementService.setSessionStorage("booking", {
			...this.details,
			payloadData: payload,
			bookingType: USER_TYPE.academy,
		});

		this._programSchedule
			.programBooking(programId as string, payload)
			.subscribe({
				next: (res) => {
					if (res.status == "success") {
						this._bookingNavigation({
							payment_url: res.payment_url,
							payment_method: payload.payment_method,
						});
					}
				},
			});
	}

	private _multipleProgramsBooking(payload: IMultipleProgramsBookingRequest) {
		this._programSchedule.multipleProgramsBooking(payload).subscribe({
			next: (res) => {
				if (res.status == "success") {
					this._bookingNavigation({
						payment_url: res.payment_url,
						payment_method: payload.payment_method,
					});
				}
			},
		});
	}

	private _bookingNavigation(payload: {
		payment_url: string | null;
		payment_method: PaymentMethodsType;
	}): void {
		if (payload.payment_method === PAYMENT_METHOD.cash) {
			this.goToNextTab();
			return;
		} else if (payload.payment_url) {
			this._bookingService.navigateToPaymobPayment(payload.payment_url);
		}
	}

	public onSelectedProgram(selectedPrograms: Map<number, IAcademyProgram>) {
		this.selectedPrograms = selectedPrograms;
	}

	public onSelectPaymentPercentage(percentage: number) {}

	showTooltip(controlName: string): boolean | null {
		const control = this.academyRegistrationForm.get(controlName);
		return control && control.invalid && control.dirty && control.touched;
	}

	getErrorTooltip(controlName: string): string {
		const control = this.academyRegistrationForm.get(controlName);
		if (control?.invalid && (control?.dirty || control?.touched)) {
			if (control?.hasError("required")) {
				return this.translateService.instant(
					"authentication.errors.required"
				);
			} else if (control?.hasError("pattern")) {
				if (controlName === "phone_number") {
					return this.translateService.instant(
						"authentication.errors.phoneNumber"
					);
				} else if (
					controlName === "name_en" ||
					controlName === "name_ar"
				) {
					return this.translateService.instant(
						"authentication.errors.username"
					);
				} else {
					return this.translateService.instant(
						"authentication.errors.pattern"
					);
				}
			} else if (control?.hasError("minlength")) {
				if (controlName === "username") {
					return this.translateService.instant(
						"authentication.errors.minlength"
					);
				} else if (controlName === "password") {
					return this.translateService.instant(
						"authentication.errors.passwordMinLength"
					);
				}
			} else if (control?.hasError("maxlength")) {
				return this.translateService.instant(
					"authentication.errors.maxlength"
				);
			} else if (control?.hasError("arabicCharacters")) {
				return this.translateService.instant(
					"authentication.errors.patternLanguage"
				);
			} else if (control?.hasError("invalidPhone")) {
				return this.translateService.instant(
					"authentication.errors.phoneNumber"
				);
			}
		}
		return "";
	}

	onAcademyRegistration() {
		let isRecaptchaVerified = false;
		// Reset reCAPTCHA field each time to avoid stale tokens
		this.academyRegistrationForm.patchValue({ recaptcha: "" });

		// Execute reCAPTCHA and handle token in the callback
		this.recaptchaV3Service
			.execute("importantAction")
			.subscribe((token: string) => {
				this.academyRegistrationForm.patchValue({ recaptcha: token });
				isRecaptchaVerified = true;

				// Prepare FormData for submission
				const formData = new FormData();
				Object.keys(this.academyRegistrationForm.value).forEach(
					(key) => {
						if (key === "phone_number") {
							const sanitizedPhoneNumber =
								this.academyRegistrationForm.value[key]
									.split(" ")
									.join("");
							formData.append(key, sanitizedPhoneNumber);
							formData.append("username", sanitizedPhoneNumber);
						} else {
							formData.append(
								key,
								this.academyRegistrationForm.value[key]
							);
						}
					}
				);

				// Submit the form
				this._authService
					.signUp(formData, USER_TYPE.academy)
					.subscribe({
						next: (res: RegisterResponse) => {
							this._authService.setRegisterSessionCycle(
								"createAccountToken",
								res.token
							);
							this._authService.setRegisterSessionCycle(
								"autoLoginData",
								res
							);

							this.goToNextTab();
						},
					});
			});
	}

	onTest() {
		console.log("FORM", this.academyRegistrationForm);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
