<ng-container *ngIf="academy()">
	<div class="flex justify-center items-center">
		<section class="lg:w-5/6 w-full lg:px-0 px-4">
			<div class="grid grid-cols-2 gap-2 md:gap-4 my-8">
				<!-- First Column -->
				<div
					class="flex col-span-2 md:col-span-1 justify-center items-start max-h-[420px]"
				>
					<div
						class="flex flex-col justify-start items-start main-image overflow-hidden gap-4 h-full"
					>
						@for (item of (academy() && academy()?.pictures.length >
						1) ? academy()?.pictures.slice(1,7) : []; track $index)
						{
						<img
							[src]="item?.url"
							(click)="imageClick($index + 1)"
							class="rounded-md w-20 h-20 object-cover cursor-pointer"
							alt=""
						/>
						}
					</div>
					<div
						class="main-image overflow-hidden h-full w-full"
						[ngClass]="{
							'ms-4': academy()?.pictures.length > 1
						}"
					>
						@if (( academy()?.pictures.length > 0)){
						<img
							[src]="academy()?.pictures[0].url"
							(click)="imageClick(0)"
							class="h-full w-full object-cover cursor-pointer rounded-2xl"
							alt=""
						/>
						} @else {
						<img
							src="assets/images/image/default/Branch-default.png"
							class="h-full w-full object-cover rounded-2xl"
							alt=""
						/>
						}
					</div>
				</div>

				<!-- Second Column -->
				<div
					class="col-span-2 md:col-span-1 bg-semiLightGray rounded-md p-2 lg:p-4 flex flex-col justify-between shadow-md"
				>
					<div class="flex justify-between items-center">
						<div>
							<strong
								class="text-xl lg:text-3xl font-bold text-textYellow"
							>
								{{ academy()?.academy_name }}
							</strong>
							<h2
								class="text-sm lg:text-xl font-light text-fontColor"
							>
								{{ academy()?.branch_name }}
							</h2>
						</div>
					</div>

					<p
						class="text-fontColor font-semiBold break-all text-sm lg:text-base mt-2 my-2 lg:mt-4 lg:my-4"
						[innerHTML]="getFormattedDescription()"
					></p>

					<div class="py-2 lg:py-4 border-y-2 border-textGreen">
						<div class="flex flex-wrap gap-3">
							@for (sport of academy()?.sport; track $index) {
							<p
								class="text-fontColor font-semibold text-xs lg:text-sm bg-lightGray px-2 lg:px-3 py-1 rounded-md"
							>
								{{ sport.name }}
							</p>
							}
						</div>
					</div>
				</div>
			</div>

			<div class="grid grid-cols-2 gap-4">
				<!-- Players Section -->
				<div class="col-span-2 rounded-lg shadow">
					<ng-container
						*ngTemplateOutlet="
							highlightData;
							context: {
								icon: ICONS.users,
								label: 'header.menu_items.players',
								value: '+' + (academy().number_of_players || 0)
							}
						"
					>
					</ng-container>
				</div>

				<!-- Championships Section -->
				<div class="col-span-1 rounded-lg shadow">
					<ng-container
						*ngTemplateOutlet="
							highlightData;
							context: {
								icon: ICONS.cup,
								label: 'SPORT_ACADEMIES.CHAMPIONSHIPS',
								value: academy().number_of_championships || 0
							}
						"
					>
					</ng-container>
				</div>

				<!-- Playgrounds Section -->
				<div class="col-span-1 rounded-lg shadow">
					<ng-container
						*ngTemplateOutlet="
							highlightData;
							context: {
								icon: ICONS.stadium,
								label: 'SPORT_ACADEMIES.PLAYGROUNDS',
								value: academy().number_of_playgrounds || 0
							}
						"
					>
					</ng-container>
				</div>
			</div>

			<div
				class="rounded-md w-full mb-16"
				[ngClass]="{ 'bg-semiLightGray': activeTab !== 1 }"
			>
				<!-- Programs -->
				<ng-container *ngIf="activeTab === 1">
					<!-- Programs List -->
					<div class="mb-8">
						<div
							class="__list-container grid grid-cols-1 md:grid-cols-4 gap-8 pt-5"
						>
							<!-- If any programs or playgrounds exist -->
							@if (( academy()?.programs.length > 0) ) {
							<!-- programs -->

							@for ( program of academy()?.programs ; track $index
							) {
							<!-- is_active -->
							@if (program.is_active) {
							<div
								class="col-span-1 md:col-span-2 program-card cursor-pointer mb-3 p-2 bg-gray-900 rounded-md flex gap-1 overflow-hidden"
								(click)="onSelectProgram(program)"
								[ngClass]="{
									selected: selectedProgram.has(program.id)
								}"
							>
								<mat-checkbox
									color="primary"
									[ngModel]="selectedProgram.has(program.id)"
								>
								</mat-checkbox>
								<div>
									<div class="grid grid-cols-12 gap-1">
										<!-- Image Section -->
										<div
											class="col-span-3 main-image w-[40px] rounded-md bg-contain bg-center"
										>
											<img
												[src]="
													program?.media[0]
														?.original_url ??
													'assets/images/image/default/Branch-default.png'
												"
												class="object-cover w-full rounded-md"
												alt="Program Image"
											/>
										</div>

										<div class="col-span-9 overflow-hidden">
											<!-- Details Section -->
											<div class="flex flex-col h-full">
												<!--  Title-->
												<div
													class="flex justify-between items-start flex-shrink-0"
												>
													<strong
														class="mx-1 text-textYellow font-bold"
														colorHex="#f9c728"
														[ngClass]="
															translateService.currentLang ==
															'en'
																? 'break-words'
																: 'break-all'
														"
													>
														{{ program.name }}
													</strong>
												</div>

												<!--  Description  -->
												<p
													[ngClass]="
														translateService.currentLang ==
														'en'
															? 'break-words'
															: 'break-all'
													"
													class="w-full mt-2 text-xs md:text-sm text-fontColor"
												>
													{{ program.description }}
												</p>
											</div>
										</div>
									</div>

									<table class="w-full">
										<tr>
											<!-- sport -->
											<td>
												<i
													class="pi pi-palette me-1 text-xs text-blue-500"
												></i>
												<span class="text-[10px]">
													{{ program.sport.name }}
												</span>
											</td>

											<!-- Start Date -->
											<td>
												<span
													class="text-xs py-1 flex items-center"
												>
													<i
														class="pi pi-calendar me-1 text-xs text-blue-500"
													></i>
													<span
														class="text-white text-xs"
													>
														{{
															program.start_date
																| date
																	: "dd/MM/yyyy"
														}}
													</span>
												</span>
											</td>
										</tr>

										<tr>
											<td colspan="2">
												@if
												(program.max_number_of_players >
												program.reservations_count) {
												<span
													class="flex items-center text-xs"
												>
													<span
														*ngIf="
															!program.is_booked
														"
														class="flex"
													>
														<i
															class="pi pi-check-circle text-xs me-2 text-[#69bb16]"
														></i>

														<span>
															{{
																"booking.available"
																	| translate
															}}
															<small
																class="block"
															>
																{{
																	" " +
																		("booking.remainingSlots"
																			| translate)
																}}
																:
																{{
																	program.max_number_of_players -
																		program.reservations_count
																}}
															</small>
														</span>
													</span>
													<span
														*ngIf="
															program.is_booked
														"
														class="text-[#d6303b]"
													>
														<i
															class="pi pi-wallet me-2 text-xs"
														></i>
														{{
															"booking.reserved"
																| translate
														}}
													</span>
												</span>
												} @else {
												<span
													class="flex items-center text-[#d6303b]"
												>
													<span>
														<i
															class="pi pi-users text-xs pr-2"
														></i>
														{{
															"booking.fullyBooked"
																| translate
														}}
													</span>
												</span>
												}
											</td>
										</tr>
										<tr>
											<!-- Price -->
											<td colspan="2">
												<i
													class="pi pi-money-bill me-1 text-xs text-textYellow"
												></i>
												<span
													class="text-[14px] text-textYellow font-bold"
												>
													{{
														program.price -
															program.price *
																(program.discount /
																	100)
													}}
													<span class="text-[8px]">
														{{
															"SPORT_ACADEMIES.Currency_eg"
																| translate
														}}
													</span>
												</span>

												@if (program.discount > 0) {
												<p
													class="diagonal-strike text-textYellow"
												>
													{{ program.price }}
												</p>
												}
											</td>
										</tr>
									</table>
								</div>
							</div>
							} } }
						</div>
					</div>
				</ng-container>
			</div>
		</section>
	</div>

	<popUp-messaging-dialog
		[visible]="dilogIsOpen"
		(onCancel)="dilogIsOpen = false"
		(onConfirm)="closeDialog($event)"
	></popUp-messaging-dialog>
</ng-container>

<ng-template #highlightData let-icon="icon" let-label="label" let-value="value">
	<div
		class="highlight-data flex flex-col items-center justify-center bg-primaryTextColor p-2 px-4 rounded-2xl"
	>
		<icon [name]="icon"></icon>
		<strong class="text-textYellow text-xs md:text-sm"
			>{{ label | translate }}
		</strong>
		<p class="text-fontColor text-2xl break-words whitespace-normal">
			<strong class="text-white">{{ value }}</strong>
		</p>
	</div>
</ng-template>
<ng-template #nameTemplate let-name="name">
	<div class="flex items-center justify-center w-full mt-8 mb-4">
		<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />

		<span
			class="name text-center text-semiDarkBlue capitalize font-extrabold text-2xl mx-4 bg-amber-400"
		>
			{{ name }}
		</span>
		<hr class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700" />
	</div>
</ng-template>
