import { CommonModule } from "@angular/common";
import {
	Component,
	inject,
	input,
	InputSignal,
	output,
} from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { permission } from "@sportyano/core/models/permissions/permission";
import { ProfilesService } from "@sportyano/core/services/profiles/profiles.service";
import {
	MessagingDialogComponent,
} from "@sportyano/shared/components";
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { CarouselModule } from "primeng/carousel";
import { GalleriaModule } from "primeng/galleria";
import { Image } from "@sportyano/core/models/profiles-images-gallery/profiles.images-gallery.model";
import { IconComponent } from "@sportyano/shared/components/icon/icon.component";
import { ICONS } from "@sportyano/shared/components/icon/models/icon";
import { FormBuilder, FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { IAcademyProgram } from "@sportyano/core/models/single-academy-details/single-academy.model";


@Component({
	selector: "academy-program-reservation",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		CarouselModule,
		GalleriaModule,
		MessagingDialogComponent,
		IconComponent,
		MatCheckboxModule,
		FormsModule,
	],
	templateUrl: "./academy-program-reservation.component.html",
	styleUrl: "./academy-program-reservation.component.scss",
})
export class AcademyProgramReservationComponent {
	//inject
	private _profiles = inject(ProfilesService);
	public translateService = inject(TranslateService);

	// Input
	academy: InputSignal<any> = input.required();
	// Output
	selected = output<Map<number, IAcademyProgram>>();

	permission = permission;
	activeTab: number = 1;
	dilogIsOpen: boolean = false;

	displayLightbox: boolean = false;
	activeIndex: number = 0;
	galleryImages: Image[] = [];
	totalCurrentSports: string[] = [];
	showShare: boolean = false;
	public ICONS = ICONS;
	public urlToShare: null | string = null;
	public isFavourite: boolean = false;
	public currentWebsiteUrl: string;
	public options: AnimationOptions = {
		path: "/assets/json/no-data.json",
		loop: true,
	};
	public selectedProgram: Map<number, IAcademyProgram> = new Map();

	ngOnInit(): void {
		this.urlToShare = `main/academies/${this.academy().slug}`;
		this.isFavourite = this.academy().is_favorite;
	}

	closeDialog(string: string) {
		this.dilogIsOpen = false;
	}

	getGalleryImages(
		typeOfUser: "player" | "playground" | "academy",
		pageDetailsId: number
	) {
		this._profiles
			.getPublicGalleryImages(typeOfUser, pageDetailsId)
			.subscribe({
				next: (res) => {
					if (res.images) {
						this.galleryImages = res.images;
					}
				},
			});
	}
	galleryVideos: any[];
	getGalleryVideos(
		typeOfUser: "players" | "playgrounds" | "academies",
		pageDetailsId: number
	) {
		this._profiles
			.getPublicGalleryVideos(typeOfUser, pageDetailsId)
			.subscribe({
				next: (res) => {
					if (res.videos) {
						this.galleryVideos = res.videos;
					}
				},
			});
	}

	slidesStore: any[] = [
		{
			src: "assets/images/homepage/alena-aenami-003-1.jpg",
		},
		{
			src: "assets/images/homepage/alena-aenami-7p-m-1k.jpg",
		},
		{
			src: "assets/images/homepage/alena-aenami-7p-m-1k.jpg",
		},
	];
	playersCarouselResponsiveOptions = [
		{
			breakpoint: "1199px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "991px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "767px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	responsiveOptions = [
		{
			breakpoint: "1500px",
			numVisible: 5,
		},
		{
			breakpoint: "1024px",
			numVisible: 3,
		},
		{
			breakpoint: "768px",
			numVisible: 2,
		},
		{
			breakpoint: "560px",
			numVisible: 1,
		},
	];

	ngOnDestroy(): void {}

	imageClick(index: number) {
		this.activeIndex = index;
		this.displayLightbox = true;
	}

	get hasTournaments(): boolean {
		return (
			this.academy() &&
			this.academy()?.programs &&
			this.academy()?.programs.length > 0 &&
			this.academy()?.programs.some(
				(p: { tournament: any }) => p.tournament
			)
		);
	}

	getFormattedDescription(): string {
		const description = this.academy().description;

		return this.convertLinksToAnchors(description);
	}

	convertLinksToAnchors(text: string): string {
		if (!text) return "";

		const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
		return text.replace(urlRegex, (url) => {
			let hyperlink = url;
			if (!hyperlink.startsWith("http")) {
				hyperlink = "https://" + hyperlink;
			}
			return `<a href="${hyperlink}" target="_blank" class="text-blue-500 underline">${url}</a>`;
		});
	}

	onSelectProgram(program: IAcademyProgram) {
		if (this.selectedProgram.has(program.id)) {
			// DELETE
			this.selectedProgram.delete(program.id);
		} else {
			// Add
			this.selectedProgram.set(program.id, program);
		}
		this.selected.emit(this.selectedProgram);
	}
}
