<!-- <h2 >Install Angular</h2> -->
<ngx-loading-bar></ngx-loading-bar>
<ngx-spinner
	bdColor="rgb(0 0 0 / 90%)"
	[zIndex]="9999"
	size="medium"
	color="#fff"
	type="cube-transition"
></ngx-spinner>
<app-header></app-header>
<div class="popup-stepper h-full flex flex-col overflow-hidden">
	<mat-dialog-content class="mat-typography flex-1 overflow-auto">
		<mat-stepper #stepper>
			<!--  Details -->
			<mat-step>
				@switch (type) {
				<!-- PROGRAM -->
				@case(QUICK_REGISTER_MODULE_TYPE.program){
				<!-- Title -->
				<ng-template matStepLabel>{{
					"quickRegister.headers.program" | translate
				}}</ng-template>
				<!-- Details -->
				<app-program-details-quick
					[details]="details"
				></app-program-details-quick>

				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						(click)="goToNextTab()"
						class="group px-5 bg-blue-500 py-2 rounded-lg my-3 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>
				}

				<!-- Academy -->
				@case(QUICK_REGISTER_MODULE_TYPE.academy){

				<ng-template matStepLabel>{{
					"quickRegister.headers.details" | translate
				}}</ng-template>

				<academy-program-reservation
					[academy]="details"
					(selected)="onSelectedProgram($event)"
				></academy-program-reservation>

				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						[disabled]="!selectedPrograms.size"
						[ngClass]="
							!selectedPrograms.size
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						(click)="goToNextTab()"
						class="group px-5 py-2 rounded-lg my-3 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>
				}

				<!-- PLAYGROUND -->
				@case(QUICK_REGISTER_MODULE_TYPE.playground){
				<!-- Title -->
				<ng-template matStepLabel>{{
					"quickRegister.headers.playground" | translate
				}}</ng-template>
				<!-- Details -->
				<playground-details [details]="details"></playground-details>

				<div class="w-full mb-16">
					<calendar-scheduler
						[playgroundDetails]="details"
						[typeOfCalender]="permission.playground"
						[navigationControllerState]="true"
						role="user"
						[startDate]="todayDate"
						[daysOff]="details.days_off"
						[timeFromTo]="{
							from: details?.time_from,
							to: details?.time_to
						}"
						(currentChosenSlots)="selectedSlots.set($event)"
						[price]="details?.price"
						[discount]="details?.discount"
					></calendar-scheduler>
				</div>
				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						(click)="goToNextTab()"
						[disabled]="!selectedSlots().length"
						[ngClass]="
							!selectedSlots().length
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						class="group px-5 py-2 rounded-lg my-3 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>

				} }
			</mat-step>

			@switch(registerAs){
			<!-- Viewer -->
			@case(USER_TYPE.viewer){
			<!-- phone  / OTP -->
			<mat-step>
				<ng-template matStepLabel>
					{{ "quickRegister.headers.phone" | translate }}
				</ng-template>

				<div class="h-full flex flex-col mt-2">
					<div class="flex items-center justify-center w-full my-6">
						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
						<span class="mx-3 font-medium text-textYellow">
							{{
								"quickRegister.labels.verifyNumber" | translate
							}}
						</span>

						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
					</div>
					@if(!showOTPStep()){

					<div @fadeUp>
						<p class="text-xs font-thin text-center">
							{{
								"quickRegister.labels.verifyNumberDescription"
									| translate
							}}
						</p>

						<form
							class="flex-1 flex flex-col"
							[formGroup]="programForm"
						>
							<div class="__username">
								<label
									for="username"
									class="capitalize text-textYellow text-xs block pb-3"
								>
									{{
										"authentication.login.form.username_email"
											| translate
									}}
								</label>
								<input
									required
									maxlength="11"
									[appCheckLanguage]="'numbers'"
									[dir]="
										translateService.currentLang === 'en'
											? 'ltr'
											: 'rtl'
									"
									id="username"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.login.form.username_placeholder'
											| translate
									}}"
									required
									formControlName="username"
								/>
								<div
									*ngIf="
										programForm.get('username')?.invalid &&
										(programForm.get('username')?.dirty ||
											programForm.get('username')
												?.touched)
									"
								>
									<small
										class="text-primaryRed text-xs mt-2"
										*ngIf="
											programForm
												.get('username')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</small>
									<small
										class="text-primaryRed text-xs mt-2"
										*ngIf="
											programForm
												.get('username')
												?.hasError(
													CustomValidationType.mobileNumberValidation
												) &&
											!programForm
												.get('username')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.egyptian_mobile_number"
												| translate
										}}
									</small>
								</div>
							</div>
							<div class="flex-1"></div>
						</form>
					</div>
					}@else {
					<div @fadeUp>
						<p class="text-xs font-thin text-center">
							{{
								"authentication.otp.a_one_time_code_has_been_sent"
									| translate
							}}
						</p>

						<section class="__otp-screen text-center text-dark">
							<div class="__otp-handler mt-10 mb-5" dir="ltr">
								<ng-otp-input
									[formCtrl]="otpFormControl"
									#ngOtpInput
									[config]="{ length: 4 }"
								></ng-otp-input>
							</div>

							<span
								class="text-fontColor font-normal text-sm capitalize block"
							>
								@if( resendOtpAttempts()<=maximumOtpAttempts){
								<span>
									{{
										"authentication.otp.dont_recive_code"
											| translate
									}}</span
								>

								<button
									[disabled]="countdownSeconds()"
									(click)="resendOtp()"
									class="font-bold py-2 px-4 rounded"
									[ngClass]="
										countdownSeconds()
											? ' text-fontColor  opacity-50 cursor-not-allowed'
											: ' important-underline text-textGreen cursor-pointer'
									"
								>
									<span
										class="font-normal text-sm capitalize block"
									>
										{{
											"authentication.otp.resend_code"
												| translate
										}}
										@if(countdownSeconds()){
										{{ countdownSeconds() }}
										}
									</span>
								</button>

								}@else{
								<span class="text-primaryRed">
									{{
										"authentication.otp.maximum_attempts"
											| translate
									}}</span
								>
								}
							</span>
						</section>
					</div>
					}
				</div>

				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					@if (!showOTPStep()) {

					<button
						[disabled]="programForm.get('phone_number')?.invalid"
						(click)="sendOtpToPhone()"
						[ngClass]="
							programForm.get('phone_number')?.invalid
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						class="px-5 py-2 my-3 rounded-lg"
					>
						{{ "quickRegister.buttons.continue" | translate }}
					</button>

					}@else {
					<button
						type="submit"
						(click)="submitOtp()"
						[disabled]="otpFormControl?.value?.length < 4"
						[ngClass]="
							otpFormControl?.value?.length < 4
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						class="px-5 py-2 my-3 rounded-lg"
					>
						{{ "quickRegister.buttons.continue" | translate }}
					</button>

					}
				</div>
			</mat-step>

			<!-- Basic FORM -->
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.basic" | translate
				}}</ng-template>

				<div class="mt-2">
					<div class="flex items-center justify-center w-full my-6">
						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
						<span class="mx-3 font-medium text-textYellow">
							{{
								"quickRegister.labels.registrationTitle"
									| translate
							}}
						</span>

						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
					</div>

					<p
						class="text-xs font-thin text-center"
						[innerHTML]="
							'quickRegister.labels.registrationDescription'
								| translate
						"
					></p>

					<form [formGroup]="playerDataInfo">
						<div class="grid grid-cols-12 gap-4">
							<!-- first_name -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.first_name_ar" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="first_name_ar"
									[appCheckLanguage]="'arabicWithoutNumbers'"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('first_name_ar')
											?.invalid &&
										(playerDataInfo.get('first_name_ar')
											?.dirty ||
											playerDataInfo.get('first_name_ar')
												?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo
												.get('first_name_ar')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- last_name -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.last_name_ar" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="last_name_ar"
									[appCheckLanguage]="'arabicWithoutNumbers'"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('last_name_ar')
											?.invalid &&
										(playerDataInfo.get('last_name_ar')
											?.dirty ||
											playerDataInfo.get('last_name_ar')
												?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo
												.get('last_name_ar')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- first_name_en -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.first_name_en" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="first_name_en"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'englishWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('first_name_en')
											?.invalid &&
										(playerDataInfo.get('first_name_en')
											?.dirty ||
											playerDataInfo.get('first_name_en')
												?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo
												.get('first_name_en')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- last_name_en -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.last_name_en" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="last_name_en"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'englishWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('last_name_en')
											?.invalid &&
										(playerDataInfo.get('last_name_en')
											?.dirty ||
											playerDataInfo.get('last_name_en')
												?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo
												.get('last_name_en')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>
							<!--viewer_email User Type viewer -->
							<div class="col-span-12 lg:col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"account.account_management.viewer_Info.Viewer_email"
											| translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="email"
									placeholder="{{
										'placeholder.email' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										email?.invalid &&
										(email?.dirty || email?.touched)
									"
								>
									<li
										*ngIf="
											email?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.email_required"
												| translate
										}}
									</li>
									<li
										*ngIf="
											email?.hasError(
												CustomValidationType.patternValidator
											) &&
											!email?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.invalid_email"
												| translate
										}}
									</li>
								</ul>
							</div>
						</div>
					</form>
				</div>
				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					<button
						(click)="onRegisterSubmit()"
						[disabled]="playerDataInfo.invalid"
						class="group px-5 py-2 rounded-lg my-3"
						[ngClass]="
							playerDataInfo.invalid
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
					>
						{{ "quickRegister.buttons.register" | translate }}
					</button>
				</div>
			</mat-step>
			}
			<!-- Academy -->
			@case (USER_TYPE.academy) {
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.basic" | translate
				}}</ng-template>

				<div class="mt-2">
					<div class="flex items-center justify-center w-full my-6">
						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
						<span
							class="mx-3 font-medium text-textYellow text-center"
						>
							{{
								"quickRegister.labels.registrationTitle"
									| translate
							}}
						</span>

						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
					</div>

					<p
						class="text-xs font-thin text-center"
						[innerHTML]="
							'quickRegister.labels.registrationDescription'
								| translate
						"
					></p>

					<form [formGroup]="academyRegistrationForm">
						<div class="grid grid-cols-12 gap-4">
							<!-- Account_name_ar_academy -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"authentication.registeration_types.account_name_ar_academy"
											| translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									required
									formControlName="name_ar"
									id="accountName_ar"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.profile_created.account_name_value'
											| translate
									}}"
									[appCheckLanguage]="'arabicWithNumbers'"
									minlength="3"
									maxlength="50"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										academyRegistrationForm.get('name_ar')
											?.invalid &&
										(academyRegistrationForm.get('name_ar')
											?.dirty ||
											academyRegistrationForm.get(
												'name_ar'
											)?.touched)
									"
								>
									<li
										*ngIf="
											academyRegistrationForm
												.get('name_ar')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{ "ac_info_errors.req" | translate }}
									</li>

									<li
										*ngIf="
											academyRegistrationForm
												.get('name_ar')
												?.hasError(
													CustomValidationType.patternValidator
												) &&
											!academyRegistrationForm
												.get('name_ar')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500 text-xs"
									>
										{{
											"ac_info_errors.Registeration_Account_Name"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- Account_name_en_academy -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"authentication.registeration_types.account_name_en_academy"
											| translate
									}}
									<span class="required">*</span>
								</p>
								<input
									aria-required="true"
									aria-describedby
									type="text"
									required
									[appCheckLanguage]="'englishWithNumbers'"
									formControlName="name_en"
									id="accountName_en"
									minlength="3"
									maxlength="50"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.registeration_types.account_name_en'
											| translate
									}}"
								/>
								<ul
									class="text-red-500 text-xs"
									*ngIf="
										academyRegistrationForm.get('name_en')
											?.invalid &&
										(academyRegistrationForm.get('name_en')
											?.dirty ||
											academyRegistrationForm.get(
												'name_en'
											)?.touched)
									"
								>
									<li
										*ngIf="
											academyRegistrationForm
												.get('name_en')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-xs"
									>
										{{ "ac_info_errors.req" | translate }}
									</li>

									<li
										*ngIf="
											academyRegistrationForm
												.get('name_ar')
												?.hasError(
													CustomValidationType.patternValidator
												) &&
											!academyRegistrationForm
												.get('name_ar')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-xs"
									>
										{{
											"ac_info_errors.Registeration_Account_Name"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- Contact_name -->
							<div class="col-span-6">
								<label
									for="contact_name"
									class="text-sm text-textYellow mb-2"
								>
									{{
										"authentication.login.form.contact_name"
											| translate
									}}
									<span class="required">*</span>
								</label>
								<input
									required
									type="text"
									formControlName="contact_name"
									[appCheckLanguage]="'englishWithoutNumbers'"
									id="contact_name"
									minlength="3"
									maxlength="50"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.login.form.contact_name'
											| translate
									}}"
								/>
								<ul
									class="text-red-500 text-xs"
									*ngIf="
										academyRegistrationForm.get(
											'contact_name'
										)?.invalid &&
										(academyRegistrationForm.get(
											'contact_name'
										)?.dirty ||
											academyRegistrationForm.get(
												'contact_name'
											)?.touched)
									"
								>
									<li
										*ngIf="
											academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.required
												)
										"
										class="text-red-500"
									>
										{{ "ac_info_errors.req" | translate }}
									</li>

									<li
										*ngIf="
											academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.containsNumbers
												) &&
											!academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"ac_info_errors.containsNumbers"
												| translate
										}}
									</li>
									<li
										*ngIf="
											academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.languageMismatch
												) &&
											!academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"ac_info_errors.languageMismatch"
												| translate
										}}
									</li>
									<li
										*ngIf="
											academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.patternValidator
												) &&
											!academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"ac_info_errors.min_max_field"
												| translate
										}}
									</li>

									<li
										*ngIf="
											academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.patternValidator
												) &&
											!academyRegistrationForm
												.get('contact_name')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"downpayment_dialog.payer_name_validation"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- Mobile_no -->
							<div class="col-span-6">
								<label
									for="mobile"
									class="text-sm text-textYellow mb-2"
								>
									{{
										"authentication.profile_created.mobile_no"
											| translate
									}}
									<span class="required">*</span>
								</label>
								<input
									type="tel"
									maxlength="11"
									[dir]="
										translateService.currentLang === 'en'
											? 'ltr'
											: 'rtl'
									"
									required
									formControlName="phone_number"
									id="mobile"
									[placeholder]="
										'authentication.login.form.phone_number_placeholder'
											| translate
									"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									[appCheckLanguage]="'numbers'"
								/>
								<ul
									class="text-red-500 text-xs"
									*ngIf="
										academyRegistrationForm.get(
											'phone_number'
										)?.invalid &&
										(academyRegistrationForm.get(
											'phone_number'
										)?.dirty ||
											academyRegistrationForm.get(
												'phone_number'
											)?.touched)
									"
								>
									<li
										class="mt-2"
										*ngIf="
											academyRegistrationForm
												.get('phone_number')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</li>
									<li
										class="mt-2"
										*ngIf="
											academyRegistrationForm
												.get('phone_number')
												?.hasError(
													CustomValidationType.mobileNumberValidation
												) &&
											!academyRegistrationForm
												.get('phone_number')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.egyptian_mobile_number"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- Password -->
							<div class="col-span-12">
								<label
									for="mobile"
									class="text-sm text-textYellow mb-2"
								>
									{{
										"authentication.login.form.password"
											| translate
									}}
									<span class="required">*</span>
								</label>
								<div class="relative w-full">
									<i
										*ngIf="showTooltip('password')"
										class="material-icons text-red-700 absolute cursor-pointer rtl:left-14 ltr:right-14 inset-y-0 flex items-center"
										[matTooltip]="
											getErrorTooltip('password')
										"
										>info_outline</i
									>
									<input
										appPreventCopyCutPaste
										[preventCopy]="true"
										[preventCut]="true"
										[preventPaste]="false"
										required
										maxlength="15"
										minlength="6"
										[type]="
											passwordVisibility
												? 'password'
												: 'text'
										"
										id="password"
										formControlName="password"
										class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
										placeholder="{{
											'authentication.login.form.password_placeholder'
												| translate
										}}"
									/>

									<div
										class="absolute inset-y-0 ltr:right-4 rtl:left-4 flex items-center pr-3"
										[attr.aria-label]="'Hide password'"
										[attr.aria-pressed]="passwordVisibility"
									>
										<mat-icon
											class="cursor-pointer text-fontColor"
											(click)="
												passwordVisibility =
													!passwordVisibility
											"
										>
											{{
												passwordVisibility
													? "visibility_off"
													: "visibility"
											}}
										</mat-icon>
									</div>
								</div>

								<div
									*ngIf="
										academyRegistrationForm.get('password')
											?.invalid &&
										(academyRegistrationForm.get('password')
											?.dirty ||
											academyRegistrationForm.get(
												'password'
											)?.touched)
									"
								>
									<small
										class="text-primaryRed text-md mt-2"
										*ngIf="
											academyRegistrationForm
												.get('password')
												?.hasError(
													CustomValidationType.patternValidator
												) &&
											!academyRegistrationForm
												.get('password')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.password_rules"
												| translate
										}}
									</small>
									<small
										class="text-primaryRed text-md mt-2"
										*ngIf="
											academyRegistrationForm
												.get('password')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</small>
								</div>
							</div>

							<!-- Policy Checkbox -->
							<!-- <div class="col-span-12">
								<div class="policy">
									<div class="__policy-checkbox inline-block">
										<p-checkbox
											[(ngModel)]="policyCheckbox"
											[binary]="true"
											[ngModelOptions]="{
												standalone: true
											}"
											inputId="policyCheckbox"
										></p-checkbox>
									</div>
									<label
										class="text-fontColor select-none cursor-pointer inline-block ps-4 my-4"
										for="policyCheckbox"
									>
										<a
											routerLink="/auth/privacy"
											target="_blank"
										>
											{{
												"authentication.registeration_types.agree_terms_conditions"
													| translate
											}}
										</a>
									</label>
								</div>
							</div> -->
						</div>
					</form>
				</div>
				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					<!-- matStepperNext -->
					<button
						class="group px-5 py-2 rounded-lg my-3"
						[ngClass]="
							academyRegistrationForm.invalid
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						[disabled]="academyRegistrationForm.invalid"
						(click)="onAcademyRegistration()"
					>
						{{ "quickRegister.buttons.register" | translate }}
					</button>
				</div>
			</mat-step>

			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.otp" | translate
				}}</ng-template>

				<div @fadeUp class="mt-3">
					<p class="text-xs font-thin text-center">
						{{
							"authentication.otp.a_one_time_code_has_been_sent"
								| translate
						}}
					</p>

					<section class="__otp-screen text-center text-dark">
						<div class="__otp-handler mt-10 mb-5" dir="ltr">
							<ng-otp-input
								[formCtrl]="otpFormControl"
								#ngOtpInput
								[config]="{ length: 4 }"
							></ng-otp-input>
						</div>

						<span
							class="text-fontColor font-normal text-sm capitalize block"
						>
							@if( resendOtpAttempts()<=maximumOtpAttempts){
							<span>
								{{
									"authentication.otp.dont_recive_code"
										| translate
								}}</span
							>
							<button
								[disabled]="countdownSeconds()"
								(click)="resendOtp()"
								class="font-bold py-2 px-4 rounded"
								[ngClass]="
									countdownSeconds()
										? ' text-fontColor  opacity-50 cursor-not-allowed'
										: ' important-underline text-textGreen cursor-pointer'
								"
							>
								<span
									class="font-normal text-sm capitalize block"
								>
									{{
										"authentication.otp.resend_code"
											| translate
									}}
									@if(countdownSeconds()){
									{{ countdownSeconds() }}
									}
								</span>
							</button>

							}@else{
							<span class="text-primaryRed">
								{{
									"authentication.otp.maximum_attempts"
										| translate
								}}</span
							>
							}
						</span>
					</section>
				</div>

				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>

					<button
						[ngClass]="
							otpFormControl?.value?.length < 4
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						(click)="submitOtp()"
						class="group px-5 bg-blue-500 py-2 rounded-lg my-3 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>
			</mat-step>
			} }

			<!-- Payment -->
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.payment" | translate
				}}</ng-template>

				<div class="flex justify-center mt-2">
					@switch (type) {
					<!-- PROGRAM -->
					@case(QUICK_REGISTER_MODULE_TYPE.program){
					<div
						class="w-fit flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<icon
							[name]="ICONS.cost"
							iconClass="w-[1.5rem] h-[1.5rem]"
						></icon>
						<div class="flex flex-col">
							<small class="text-textYellow">{{
								"account.academy_programs_schedule.price"
									| translate
							}}</small>
							<p class="text-fontColor text-base break-words">
								<strong class="text-white pr-1">{{
									details.total_amount | number : "2.0-1"
								}}</strong>
								<small>{{
									"SPORT_ACADEMIES.Currency_eg" | translate
								}}</small>
							</p>
						</div>
					</div>

					}

					<!-- Academy -->
					@case(QUICK_REGISTER_MODULE_TYPE.academy){
					<div
						class="w-fit flex flex-col gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<div>
							<icon
								[name]="ICONS.cost"
								iconClass="w-[1.5rem] h-[1.5rem]"
							></icon>
							<div class="flex flex-col">
								<small class="text-textYellow">{{
									"account.academy_programs_schedule.price"
										| translate
								}}</small>
							</div>
						</div>
						<!-- <div class="grid grid-col-3 gap-1"> -->
						<mat-button-toggle-group
							[hideSingleSelectionIndicator]="true"
							[formControl]="paymentPercentage"
							aria-label="Font Style"
						>
							<!-- 20 -->
							<mat-button-toggle [value]="20" class="text-center">
								<strong class="block text-lightBlue"
									>20%
								</strong>
								<span
									>{{ getTotalSelectedProgram * 0.2 }}

									<small>{{
										"SPORT_ACADEMIES.Currency_eg"
											| translate
									}}</small>
								</span>
							</mat-button-toggle>
							<!-- 50 -->
							<mat-button-toggle [value]="50">
								<strong class="block text-textYellow"
									>50%
								</strong>
								<span
									>{{ getTotalSelectedProgram * 0.5 }}

									<small>{{
										"SPORT_ACADEMIES.Currency_eg"
											| translate
									}}</small>
								</span>
							</mat-button-toggle>
							<!-- 100 -->
							<mat-button-toggle [value]="100">
								<strong class="block text-green-600"
									>100%
								</strong>
								<span
									>{{ getTotalSelectedProgram }}

									<small>{{
										"SPORT_ACADEMIES.Currency_eg"
											| translate
									}}</small>
								</span>
							</mat-button-toggle>
						</mat-button-toggle-group>

						<!-- </div> -->
					</div>

					}

					<!-- Playground -->
					@case(QUICK_REGISTER_MODULE_TYPE.playground){

					<div
						class="w-fit flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<icon
							[name]="ICONS.cost"
							iconClass="w-[1.5rem] h-[1.5rem]"
						></icon>
						<div class="flex flex-col">
							<small class="text-textYellow">{{
								"payment_info.total_price" | translate
							}}</small>
							<small class="text-semiDarkGrey">
								{{
									("booking.numberOfBookingSlots"
										| translate) +
										" " +
										selectedSlots()?.length
								}}
							</small>
							<p class="text-fontColor text-base">
								{{ playgroundBookingPrice }}
								<small>{{ "le" | translate }}</small>
							</p>
						</div>
					</div>

					} }
				</div>
				<div class="mt-2">
					<h3 class="text-lg font-bold text-[#F8F9FA]">
						{{ "payment.select_payment" | translate }}
					</h3>

					<form [formGroup]="paymentMethodForm">
						<mat-accordion
							class="payment-method-accordion"
							[hideToggle]="true"
						>
							<mat-expansion-panel
								(opened)="setPaymentMethod(PAYMENT_METHOD.cash)"
								[expanded]="
									paymentMethodForm.value.payment_method ==
									PAYMENT_METHOD.cash
								"
							>
								<mat-expansion-panel-header
									[ngClass]="
										paymentMethodForm.value
											.payment_method ==
										PAYMENT_METHOD.cash
											? 'no-events'
											: 'default'
									"
								>
									<mat-panel-title class="flex gap-2">
										<input
											type="radio"
											name="payment_method"
											[value]="PAYMENT_METHOD.cash"
											formControlName="payment_method"
											(change)="
												setPaymentMethod(
													PAYMENT_METHOD.cash
												)
											"
										/>
										<span class="font-semibold">{{
											"booking.paymentMethod.cash"
												| translate
										}}</span>
									</mat-panel-title>
								</mat-expansion-panel-header>
							</mat-expansion-panel>
							<mat-expansion-panel
								[expanded]="
									paymentMethodForm.value.payment_method ==
									PAYMENT_METHOD.online
								"
								(opened)="
									setPaymentMethod(PAYMENT_METHOD.online)
								"
							>
								<mat-expansion-panel-header
									[ngClass]="
										paymentMethodForm.value
											.payment_method ==
										PAYMENT_METHOD.online
											? 'no-events'
											: 'default'
									"
								>
									<mat-panel-title class="flex gap-2">
										<input
											type="radio"
											name="payment_method"
											[value]="PAYMENT_METHOD.online"
											formControlName="payment_method"
											[disabled]="isOnlinePaymentDisabled"
											[attr.tabindex]="
												isOnlinePaymentDisabled
													? '-1'
													: null
											"
										/>

										<span class="font-semibold">{{
											"payment.online" | translate
										}}</span>
									</mat-panel-title>
									<mat-panel-description
										class="flex justify-end"
									>
										<div
											class="bg-white rounded-md px-2 py-1 flex gap-2"
										>
											@for (paymentType of
											availablePaymentTypes; track $index)
											{
											<img
												[src]="paymentType.icon"
												[alt]="paymentType.name"
												class="w-10 h-5 object-contain"
											/>
											}
										</div>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<h4
									class="text-md font-semibold text-[#F8F9FA]"
								>
									{{ "payment.payment_method" | translate }}
								</h4>

								<label
									*ngFor="let type of availablePaymentTypes"
									class="online-payment-method flex items-center gap-3 my-2 py-2 px-4 rounded-lg cursor-pointer transition hover:bg-[#e4e4e4]"
									[ngClass]="
										paymentMethodForm.get('payment_type')
											?.value == type.key
											? 'online-payment-method-active'
											: ''
									"
								>
									<input
										type="radio"
										name="payment_type"
										[value]="type.key"
										(change)="selectPaymentType(type)"
									/>
									<div
										class="w-full flex justify-between items-center"
									>
										<span class="font-medium">{{
											"payment." + type.name | translate
										}}</span>
										<img
											[src]="type.icon"
											[alt]="type.name"
											class="w-20 h-10 object-contain"
										/>
									</div>
								</label>
							</mat-expansion-panel>

							<mat-expansion-panel
								[expanded]="
									paymentMethodForm.value.payment_method ==
									PAYMENT_METHOD.other
								"
								(opened)="
									setPaymentMethod(PAYMENT_METHOD.other)
								"
							>
								<mat-expansion-panel-header
									[ngClass]="
										paymentMethodForm.value
											.payment_method ==
										PAYMENT_METHOD.other
											? 'no-events'
											: 'default'
									"
								>
									<mat-panel-title class="flex gap-2">
										<input
											type="radio"
											name="payment_method"
											[value]="PAYMENT_METHOD.other"
											formControlName="payment_method"
										/>

										<span class="font-semibold">{{
											"payment.other" | translate
										}}</span>
									</mat-panel-title>
									<mat-panel-description
										class="flex justify-end"
									>
										<div
											class="bg-white rounded-md px-2 py-1 flex gap-2"
										>
											<img
												src="assets/images/image/online-wallet-instapay.webp"
												alt="instapay"
												class="w-10 h-5 object-contain"
											/>
										</div>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<div>
									<p class="text-center">
										{{
											"quickRegister.labels.instapay_text"
												| translate
										}}
									</p>
									<a
										href="https://wa.me/01101003140"
										target="_blank"
										class="text-textGreen cursor-pointer flex items-center justify-center gap-2"
									>
										<span>
											<icon
												[name]="ICONS.whatsapp"
												iconClass="w-[10px] h-[10px]"
											></icon>
										</span>
										01101003140</a
									>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
					</form>
				</div>
				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>

					<button
						(click)="onBooking()"
						class="group px-5 py-2 rounded-lg my-3 bg-blue-500 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>
			</mat-step>

			<!-- Done -->
			<mat-step>
				<ng-template matStepLabel>{{
					"account.academy_programs_schedule.done" | translate
				}}</ng-template>
				<div class="container mx-auto text-center">
					<div class="flex justify-center">
						<ng-lottie
							class="w-1/3 md:w-1/6"
							[options]="congratsLottie"
						/>
					</div>
					<div class="text-2xl font-bold text-green-500">
						{{ "congratulations" | translate }}
					</div>

					<!-- <p>
						{{
							"quickRegister.labels.registrationSuccessfully"
								| translate
						}}
					</p> -->

					<div class="grid grid-cols-2 gap-5 mt-3">
						@switch (type) {
						<!-- PROGRAM -->
						@case(QUICK_REGISTER_MODULE_TYPE.academy){
						<!-- Reserved Programs -->
						<div
							class="flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
						>
							<icon
								[name]="ICONS.sport"
								iconClass="w-[1.5rem] h-[1.5rem]"
							></icon>
							<div class="flex flex-col">
								<small class="text-textYellow">{{
									"quickRegister.labels.reservedPrograms"
										| translate
								}}</small>
								<ul
									class="text-fontColor text-base break-words pr-1"
								>
									@for (item of selectedPrograms | keyvalue;
									track $index) {
									<li>{{ item.value.name }}</li>
									}
								</ul>
							</div>
						</div>

						<!-- Total price -->
						<ng-container
							*ngTemplateOutlet="
								summary;
								context: {
									icon: ICONS.cost,
									label: 'payment_info.total_price',
									value: getTotalSelectedProgram
								}
							"
						>
						</ng-container>

						<!-- Partial Payment -->
						@if(paymentPercentage.value < 100) {
						<ng-container
							*ngTemplateOutlet="
								summary;
								context: {
									icon: ICONS.discount,
									label: 'quickRegister.labels.partialPayment',
									value:
										getTotalSelectedProgram *
										(paymentPercentage.value / 100)
								}
							"
						>
						</ng-container>
						} }

						<!-- Program -->
						@case (QUICK_REGISTER_MODULE_TYPE.program) {

						<!-- Program Name -->
						<ng-container
							*ngTemplateOutlet="
								summary;
								context: {
									icon: ICONS.sport,
									label: 'quickRegister.headers.Program',
									value:
										translateService.currentLang === 'en'
											? details.name_en
											: details.name_ar
								}
							"
						>
						</ng-container>

						<!-- Total Price -->
						<ng-container
							*ngTemplateOutlet="
								summary;
								context: {
									icon: ICONS.cost,
									label: 'payment_info.total_price',
									value:
										details.total_amount | number : '2.0-1'
								}
							"
						>
						</ng-container>

						}

						<!-- Playground -->
						@case(QUICK_REGISTER_MODULE_TYPE.playground){
						<!-- total_price -->
						<ng-container
							*ngTemplateOutlet="
								itemData;
								context: {
									label: 'payment_info.total_price',
									value: playgroundBookingPrice,
									valueLabel: 'le'
								}
							"
						>
						</ng-container>

						<!-- Number Of slots -->
						<ng-container
							*ngTemplateOutlet="
								itemData;
								context: {
									label: 'booking.numberOfBookingSlots',
									value: selectedSlots()?.length
								}
							"
						>
						</ng-container>
						<!-- Booking slots -->
						<div
							class="flex flex-col items-start gap-4 bg-semiDarkBlue p-4 rounded-2xl"
						>
							<div
								class="flex flex-col w-full justify-between gap-2"
							>
								<small class="text-textGreen">
									{{ "booking.BookingSlots" | translate }}
								</small>
								<ul class="text-white">
									@for (slot of selectedSlots(); track $index)
									{
									<li>
										<small>{{ slot?.slot }}</small>
									</li>
									}
								</ul>
							</div>
						</div>
						} }
					</div>

					<div class="my-2">
						{{
							"quickRegister.labels.redirectToProgramDetails"
								| translate
						}}
						<span class="text-lightBlue">{{
							countdownRedirect() +
								" " +
								("dateTime.sec" | translate)
						}}</span>
					</div>
				</div>
				<div
					class="fixed bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					<button
						(click)="closeDialog()"
						class="group px-5 py-2 rounded-lg my-3 bg-blue-500"
					>
						{{ "quickRegister.buttons.redirect" | translate }}
					</button>
				</div>
			</mat-step>
		</mat-stepper>
	</mat-dialog-content>
</div>

<ng-template #summary let-icon="icon" let-label="label" let-value="value">
	<div
		class="flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
	>
		<icon [name]="icon" iconClass="w-[1.5rem] h-[1.5rem]"></icon>
		<div class="flex flex-col">
			<small class="text-textYellow">{{ label | translate }}</small>
			<p class="text-fontColor text-base break-words">
				<strong *ngIf="value" class="text-white pr-1">{{
					value
				}}</strong>
				<ng-content></ng-content>
			</p>
		</div>
	</div>
</ng-template>

<ng-template
	#itemData
	let-label="label"
	let-subLabel="subLabel"
	let-value="value"
	let-valueLabel="valueLabel"
>
	<div class="flex flex-col items-start gap-4bg-darkBlue p-4 rounded-2xl">
		<div class="flex flex-col w-full justify-between gap-2 break-all">
			<small class="text-textGreen break-all">
				{{ label | translate }}
			</small>
			<small *ngIf="subLabel" class="text-semiDarkGrey break-words">
				{{ subLabel | translate }}
			</small>
			<p class="text-fontColor text-base break-words">
				{{ value }}
				<small>{{ valueLabel | translate }}</small>
			</p>
		</div>
	</div>
</ng-template>
